import { Campaign, CampaignDomain, EMPTY_DOMAIN } from "./Campaign"
import LocationSearchParser from "../property/LocationSearchParser"
import { DomainUtil } from "../util/DomainUtil"

export class CampaignParser {
  parse(): Campaign {
    const params = LocationSearchParser.getQueryParams()
    return {
      utm_campaign: params[UTM_CAMPAIGN],
      utm_content: params[UTM_CONTENT],
      utm_id: params[UTM_ID],
      utm_medium: params[UTM_MEDIUM],
      utm_source: params[UTM_SOURCE],
      utm_term: params[UTM_TERM],

      gclid: params[GCLID],
      fbclid: params[FBCLID]
    }
  }

  parseDomain(): CampaignDomain {
    if (typeof window === "undefined") {
      return EMPTY_DOMAIN
    }

    const referrerDomain = (): string | undefined => {
      const referrer = window.document.referrer
      if (!referrer) return undefined
      const referrerHostname = new URL(referrer).hostname
      return DomainUtil.extractDomain(referrerHostname)
    }

    return {
      current: DomainUtil.extractDomain(window.document.location.hostname),
      referrer: referrerDomain()
    }
  }
}

export const UTM_CAMPAIGN = "utm_campaign"
export const UTM_CONTENT = "utm_content"
export const UTM_ID = "utm_id"
export const UTM_MEDIUM = "utm_medium"
export const UTM_SOURCE = "utm_source"
export const UTM_TERM = "utm_term"

export const GCLID = "gclid"
export const FBCLID = "fbclid"
