import { sanitizeUser, User } from "../../../core/internal/model/model"
import { IStorage } from "../../../core/internal/storage/Storage"
import { UserListener } from "../../../core/internal/user/UserListener"
import { isSameUser, mergeUsers, UserManager } from "../../../core/internal/user/UserManager"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"
import { DEVICE_ID_STORAGE_KEY, USER_ID_STORAGE_KEY } from "../../../config"
import { PropertyOperations } from "../../property/PropertyOperations"

export class UserManagerImpl implements UserManager {
  private userListeners: UserListener[] = []
  private readonly defaultUser: User = { deviceId: this.hackleDeviceId }
  private _currentUser: User

  constructor(
    private storage: UserStorage,
    private hackleDeviceId: string,
    previousUser: User | null = null,
    initUser: User | null = null
  ) {
    this._currentUser = initUser ?? previousUser ?? this.defaultUser
    this.storage.saveUser(this._currentUser)
  }

  public addListener(listener: UserListener): void {
    this.userListeners.push(listener)
  }

  public get currentUser() {
    return this._currentUser
  }

  public resolveCurrentOrNull(user: User | string | undefined): User | null {
    if (user === undefined) {
      return this.currentUser
    }

    if (typeof user === "string") {
      return this.setUser({ id: user })
    }

    const sanitizedUser = sanitizeUser(user)
    if (sanitizedUser) {
      return this.setUser(sanitizedUser)
    } else {
      return null
    }
  }

  public setUser(user: User): User {
    return this.updateUser(user)
  }

  public setUserId(userId: string | undefined): User {
    const user: User = {
      ...this._currentUser,
      userId
    }
    return this.setUser(user)
  }

  public setDeviceId(deviceId: string): User {
    const user: User = {
      ...this._currentUser,
      deviceId
    }
    return this.setUser(user)
  }

  public updateUserProperties(operations: PropertyOperations): User {
    return this.operateProperties(operations)
  }

  public resetUser(): User {
    return this.setUser(this.defaultUser)
  }

  private changeUser(oldUser: User, newUser: User, timestamp: number) {
    this.userListeners.forEach((listener) => {
      listener.onUserUpdated(oldUser, newUser, timestamp)
    })
  }

  private saveUser(user: User) {
    this.storage.saveUser(user)
  }

  private update(updater: (user: User) => User): User {
    const oldUser = this._currentUser
    const newUser = updater(oldUser)
    this._currentUser = newUser

    if (!isSameUser(oldUser, newUser)) {
      this.changeUser(oldUser, newUser, new Date().getTime())
      this.saveUser(newUser)
    }

    return newUser
  }

  private updateUser(user: User): User {
    return this.update((currentUser) => mergeUsers(currentUser, user))
  }

  private operateProperties(operations: PropertyOperations): User {
    return this.update((currentUser) => {
      const userProperties = currentUser?.properties ?? {}
      const properties = operations.operate(new Map(Object.entries(userProperties)))

      return { ...currentUser, properties: ObjectUtil.fromMap(properties) }
    })
  }
}

export class UserStorage {
  constructor(private readonly storage: IStorage) {}

  public getUser(): User | null {
    const deviceId = this.deviceId || undefined
    const userId = this.userId || undefined

    if (deviceId !== undefined || userId !== undefined) {
      return { deviceId, userId }
    }

    return null
  }

  public saveUser(user: User) {
    this.setDeviceId(user.deviceId || null)
    this.setUserId(user.userId || null)
  }

  public get deviceId(): string | null {
    return this.storage.getItem(DEVICE_ID_STORAGE_KEY)
  }

  public get userId(): string | null {
    return this.storage.getItem(USER_ID_STORAGE_KEY)
  }

  public setDeviceId(deviceId: string | null) {
    this.setId(DEVICE_ID_STORAGE_KEY, deviceId)
  }

  public setUserId(userId: string | null) {
    this.setId(USER_ID_STORAGE_KEY, userId)
  }

  private setId(key: string, value: string | null) {
    if (ObjectUtil.isNotNullOrUndefined(value)) {
      this.storage.setItem(key, value)
    } else {
      this.storage.removeItem(key)
    }
  }
}
