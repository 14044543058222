export interface UrlHandler {
  open: (url: URL) => void
}

export class UrlLinkHandler implements UrlHandler {
  open(url: URL): void {
    window.location.href = url.href
  }
}

export class UrlLinkNewWindowHandler implements UrlHandler {
  open(url: URL): void {
    window.open(url.href, "Hackle", "resizable:true")
  }
}

export class UrlLinkNewTabHandler implements UrlHandler {
  open(url: URL): void {
    window.open(url.href)
  }
}
