export default class HackleUrlResolver {
  static serverFetch(sdkBaseUrl: string): string {
    return `${sdkBaseUrl}/api/v2/workspaces`
  }

  static clientFetch(sdkBaseUrl: string, sdkKey: string): string {
    return `${sdkBaseUrl}/api/v2/w/${sdkKey}/w`
  }

  static serverDispatch(eventBaseUrl: string): string {
    return `${eventBaseUrl}/api/v2/events`
  }

  static clientDispatch(eventBaseUrl: string): string {
    return `${eventBaseUrl}/api/v2/w/events`
  }

  static beaconDispatch(eventBaseUrl: string, sdkKey: string): string {
    return `${eventBaseUrl}/api/v2/w/${sdkKey}/events`
  }

  static inAppMessageRenderer(cdnUrl: string, version: string): string {
    return `${cdnUrl}/sdk/iam-renderer/${version}/iam-renderer.js`
  }
}
