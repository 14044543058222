import { InAppMessageRenderer } from "../script/InAppMessageRenderer"
import { InAppMessageUi } from "../InAppMessageUi"
import { InAppMessageRenderScriptLoader } from "../script/InAppMessageRenderScriptLoader"
import { InAppMessageView } from "./InAppMessageView"
import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"
import { InAppMessageEvent } from "../event/InAppMessageEvent"
import { InAppMessageInteraction } from "../event/InAppMessageInteraction"

export class ScriptInAppMessageView implements InAppMessageView {
  private static HACKLE_IAM_INTERACTION_EVENT_TYPE = "hackle-iam-interaction"
  private static CONTAINER_CLASS_NAME = "hackle-iam"

  private container: HTMLElement | null = null
  private renderer: InAppMessageRenderer | null = null

  constructor(
    private readonly scriptLoader: InAppMessageRenderScriptLoader,
    public readonly context: InAppMessagePresentationContext,
    public readonly ui: InAppMessageUi
  ) {}

  async open() {
    try {
      // add in-app message interaction listener
      window.addEventListener(
        ScriptInAppMessageView.HACKLE_IAM_INTERACTION_EVENT_TYPE,
        this.handleInAppMessageInteraction
      )

      // load script
      await this.scriptLoader.load()

      // add view container
      const container = this.addContainer()
      container.style.display = "none"
      container.classList.add(`${ScriptInAppMessageView.CONTAINER_CLASS_NAME}--loading`)

      // instantiate renderer
      const script = (window as any).HackleInAppMessageRenderer
      this.renderer = new script.HackleInAppMessageRenderer(container) as InAppMessageRenderer

      // render
      await this.renderer.render({
        messageId: this.context.inAppMessage.id,
        message: this.context.message.toJson()
      })

      container.classList.remove(`${ScriptInAppMessageView.CONTAINER_CLASS_NAME}--loading`)
      container.style.display = "block"
      this.handleEvent({ type: "IMPRESSION" })
    } catch (e) {
      this.close()
    }
  }

  close(): void {
    this.handleEvent({ type: "CLOSE" })
    window.removeEventListener(
      ScriptInAppMessageView.HACKLE_IAM_INTERACTION_EVENT_TYPE,
      this.handleInAppMessageInteraction
    )
    this.renderer?.destroy()
    this.renderer = null
    this.removeContainer()
    this.ui.currentMessageView = null
  }

  private addContainer() {
    if (!this.container) {
      const container = document.createElement("div")
      container.classList.add(ScriptInAppMessageView.CONTAINER_CLASS_NAME)
      this.container = container
    }

    if ((process.env.NODE_ENV as string | undefined) === "test") {
      this.container.setAttribute("data-testid", ScriptInAppMessageView.CONTAINER_CLASS_NAME)
    }

    if (!this.container.parentNode) {
      document.body.appendChild(this.container)
    }

    return this.container
  }

  private removeContainer() {
    if (this.container) {
      this.container.parentNode?.removeChild(this.container)
      this.container = null
    }
  }

  private handleInAppMessageInteraction = (e: Event) => {
    if (e instanceof CustomEvent && e.type === ScriptInAppMessageView.HACKLE_IAM_INTERACTION_EVENT_TYPE) {
      const interaction: InAppMessageInteraction = e.detail
      this.handleInteraction(interaction)
    }
  }

  private handleInteraction(interaction: InAppMessageInteraction) {
    const interactionHandler = this.ui.interactionHandlerFactory.get(interaction)
    if (!interactionHandler) return
    interactionHandler.handle(this, interaction)
  }

  private handleEvent(event: InAppMessageEvent) {
    this.ui.eventHandler.handle(this, event)
  }
}
