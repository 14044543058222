import Event from "../../../core/internal/event/Event"
import { InAppMessagePresenter } from "../presentation/InAppMessagePresenter"
import { UserEventEmitter } from "../../../core/internal/event/UserEventEmitter"
import { InAppMessageDeterminer } from "./InAppMessageDeterminer"

export default class InAppMessageManager {
  constructor(
    private readonly userEventEmitter: UserEventEmitter,
    private readonly determiner: InAppMessageDeterminer,
    private readonly presenter: InAppMessagePresenter
  ) {
    this.initialize()
  }

  private initialize() {
    this.userEventEmitter.on("event", this.onEvent)
  }

  public destroy() {
    this.userEventEmitter.off("event", this.onEvent)
  }

  private onEvent = (event: Event) => {
    const context = this.determiner.determineOrNull(event)
    if (!context) return

    this.presenter.present(context)
  }
}
