import Workspace from "../../workspace/Workspace"
import { Experiment, HackleUser } from "../../model/model"
import ExperimentEvaluation from "./experiment/ExperimentEvaluation"
import Event from "../../event/Event"

export default interface Evaluator {
  evaluate(request: EvaluatorRequest, context: EvaluatorContext): EvaluatorEvaluation
}

export type EvaluatorType = "EXPERIMENT" | "REMOTE_CONFIG" | "IN_APP_MESSAGE"

export class EvaluatorKey {
  readonly type: EvaluatorType
  readonly id: number | string

  constructor(type: EvaluatorType, id: number | string) {
    this.type = type
    this.id = id
  }

  isEquals(other: EvaluatorKey): boolean {
    return this.type === other.type && this.id === other.id
  }
}

export interface EvaluatorRequest {
  readonly key: EvaluatorKey
  readonly workspace: Workspace
  readonly user: HackleUser

  toString(): string
}

export interface EventEvaluatorRequest extends EvaluatorRequest {
  readonly event: Event
}

export interface EvaluatorEvaluation {
  readonly reason: string
  readonly targetEvaluations: EvaluatorEvaluation[]
}

export class EvaluatorContext {
  readonly _stack: EvaluatorRequest[]
  readonly _targetEvaluations: EvaluatorEvaluation[]

  private constructor() {
    this._stack = []
    this._targetEvaluations = []
  }

  static create(): EvaluatorContext {
    return new EvaluatorContext()
  }

  get stack(): EvaluatorRequest[] {
    return Array.from(this._stack)
  }

  get targetEvaluations(): EvaluatorEvaluation[] {
    return Array.from(this._targetEvaluations)
  }

  contains(request: EvaluatorRequest): boolean {
    return this._stack.some((it) => it.key.isEquals(request.key))
  }

  addRequest(request: EvaluatorRequest): void {
    this._stack.push(request)
  }

  removeRequest(request: EvaluatorRequest): void {
    const index = this._stack.indexOf(request, 0)
    if (index > -1) {
      this._stack.splice(index, 1)
    }
  }

  get(experiment: Experiment): EvaluatorEvaluation | undefined {
    return this._targetEvaluations.find(
      (it) => it instanceof ExperimentEvaluation && it.experiment.id === experiment.id
    )
  }

  addEvaluation(evaluation: EvaluatorEvaluation): void {
    this._targetEvaluations.push(evaluation)
  }
}
