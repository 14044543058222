import {
  Bucket,
  BucketId,
  Container,
  ContainerId,
  EventKey,
  EventType,
  Experiment,
  ExperimentKey,
  InAppMessage,
  InAppMessageKey,
  ParameterConfiguration,
  ParameterConfigurationId,
  RemoteConfigParameter,
  RemoteConfigParameterKey,
  Segment,
  SegmentKey
} from "../model/model"
import { WorkspaceDto } from "./dto"
import CollectionUtil from "../util/CollectionUtil"

export default class Workspace {
  private readonly experiments: Map<ExperimentKey, Experiment>
  private readonly featureFlags: Map<ExperimentKey, Experiment>
  private readonly buckets: Map<BucketId, Bucket>
  private readonly eventTypes: Map<EventKey, EventType>
  private readonly segments: Map<SegmentKey, Segment>
  private readonly containers: Map<ContainerId, Container>
  private readonly parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration>
  private readonly remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter>
  private readonly inAppMessages: Map<InAppMessageKey, InAppMessage>

  constructor(
    experiments: Experiment[],
    featureFlags: Experiment[],
    buckets: Bucket[],
    eventTypes: EventType[],
    segments: Segment[],
    containers: Container[],
    parameterConfigurations: ParameterConfiguration[],
    remoteConfigParameters: RemoteConfigParameter[],
    inAppMessages: InAppMessage[]
  ) {
    this.experiments = CollectionUtil.associateBy(experiments, (it) => it.key)
    this.featureFlags = CollectionUtil.associateBy(featureFlags, (it) => it.key)
    this.buckets = CollectionUtil.associateBy(buckets, (it) => it.id)
    this.eventTypes = CollectionUtil.associateBy(eventTypes, (it) => it.key)
    this.segments = CollectionUtil.associateBy(segments, (it) => it.key)
    this.containers = CollectionUtil.associateBy(containers, (it) => it.id)
    this.parameterConfigurations = CollectionUtil.associateBy(parameterConfigurations, (it) => it.id)
    this.remoteConfigParameters = CollectionUtil.associateBy(remoteConfigParameters, (it) => it.key)
    this.inAppMessages = CollectionUtil.associateBy(inAppMessages, (it) => it.key)
  }

  getExperiments() {
    return Array.from(this.experiments.values())
  }

  getFeatureFlags() {
    return Array.from(this.featureFlags.values())
  }

  getInAppMessages(): InAppMessage[] {
    return Array.from(this.inAppMessages.values())
  }

  getExperimentOrNull(experimentKey: ExperimentKey): Experiment | undefined {
    return this.experiments.get(experimentKey)
  }

  getFeatureFlagOrNull(featureKey: ExperimentKey): Experiment | undefined {
    return this.featureFlags.get(featureKey)
  }

  getBucketOrNull(bucketId: BucketId): Bucket | undefined {
    return this.buckets.get(bucketId)
  }

  getEventTypeOrNull(eventKey: EventKey): EventType | undefined {
    return this.eventTypes.get(eventKey)
  }

  getSegmentOrNull(segmentKey: SegmentKey): Segment | undefined {
    return this.segments.get(segmentKey)
  }

  getContainerOrNull(containerId: ContainerId): Container | undefined {
    return this.containers.get(containerId)
  }

  getParameterConfigurationOrNull(
    parameterConfigurationId: ParameterConfigurationId
  ): ParameterConfiguration | undefined {
    return this.parameterConfigurations.get(parameterConfigurationId)
  }

  getRemoteConfigParameterOrNull(remoteConfigParameterKey: string): RemoteConfigParameter | undefined {
    return this.remoteConfigParameters.get(remoteConfigParameterKey)
  }

  getInAppMessageOrNull(messageKey: InAppMessageKey) {
    return this.inAppMessages.get(messageKey)
  }

  static from(dto: WorkspaceDto): Workspace {
    const experiments = CollectionUtil.mapNotNullOrUndefined(dto.experiments, (it) =>
      Experiment.fromJson("AB_TEST", it)
    )

    const featureFlags = CollectionUtil.mapNotNullOrUndefined(dto.featureFlags, (it) =>
      Experiment.fromJson("FEATURE_FLAG", it)
    )

    const buckets = dto.buckets.map(Bucket.fromJson)
    const eventTypes = dto.events.map(EventType.fromJson)
    const segments = CollectionUtil.mapNotNullOrUndefined(dto.segments, Segment.fromJson)
    const containers = dto.containers.map(Container.fromJson)
    const parameterConfigurations = dto.parameterConfigurations.map(ParameterConfiguration.fromJson)
    const remoteConfigParameters = CollectionUtil.mapNotNullOrUndefined(
      dto.remoteConfigParameters,
      RemoteConfigParameter.fromJson
    )
    const inAppMessages = CollectionUtil.mapNotNullOrUndefined(dto.inAppMessages, InAppMessage.fromJson)

    return new Workspace(
      experiments,
      featureFlags,
      buckets,
      eventTypes,
      segments,
      containers,
      parameterConfigurations,
      remoteConfigParameters,
      inAppMessages
    )
  }
}
