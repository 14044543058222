import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"
import { InAppMessageUi } from "../InAppMessageUi"
import { InAppMessageView } from "./InAppMessageView"
import { ScriptInAppMessageView } from "./ScriptInAppMessageView"
import { InAppMessageRenderScriptLoader } from "../script/InAppMessageRenderScriptLoader"

export class InAppMessageViewFactory {
  constructor(private readonly scriptLoader: InAppMessageRenderScriptLoader) {}

  create(context: InAppMessagePresentationContext, ui: InAppMessageUi): InAppMessageView {
    return new ScriptInAppMessageView(this.scriptLoader, context, ui)
  }
}
