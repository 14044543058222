import { PropertyOperations } from "../../../hackle/property/PropertyOperations"
import { User } from "../model/model"
import { UserListener } from "./UserListener"

export interface UserManager {
  readonly currentUser: User

  addListener(listener: UserListener): void

  resolveCurrentOrNull(user: User | string | undefined): User | null

  setUser(user: User): User

  setUserId(userId: string | undefined): User

  setDeviceId(deviceId: string): User

  updateUserProperties(operations: PropertyOperations): User

  resetUser(): User
}

export const isSameUser = (a: User | null, b: User): boolean => {
  if (a === null) return false
  return a.userId === b.userId && a.deviceId === b.deviceId
}

export const mergeUsers = (oldUser: User, newUser: User): User => {
  if (isSameUser(oldUser, newUser)) {
    return {
      ...newUser,
      properties: {
        ...oldUser.properties,
        ...newUser.properties
      }
    }
  }
  return newUser
}
