import Workspace from "../../../core/internal/workspace/Workspace"
import { InAppMessage } from "../../../core/internal/model/model"
import Event from "../../../core/internal/event/Event"
import { InAppMessageEventTriggerDeterminer } from "./InAppMessageEventTriggerDeterminer"

export class InAppMessageEventMatcher {
  constructor(
    private readonly ruleDeterminer: InAppMessageEventTriggerDeterminer,
    private readonly frequencyCapDeterminer: InAppMessageEventTriggerDeterminer
  ) {}

  matches(workspace: Workspace, inAppMessage: InAppMessage, event: Event): boolean {
    if (!Event.isTrack(event)) return false
    if (!this.ruleDeterminer.isTriggerTarget(workspace, inAppMessage, event)) {
      return false
    }

    return this.frequencyCapDeterminer.isTriggerTarget(workspace, inAppMessage, event)
  }
}
