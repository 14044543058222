import { SessionListener } from "../../core/internal/session/SessionListener"
import { User } from "../../core/internal/model/model"
import { CampaignParser } from "./CampaignParser"
import { Campaign, EMPTY_CAMPAIGN, isNewCampaign } from "./Campaign"
import { CampaignStorage } from "./CampaignStorage"
import { PropertyOperationsBuilder } from "../property/PropertyOperations"
import HackleCore from "../../core/HackleCore"
import { UserManager } from "../../core/internal/user/UserManager"
import { HackleUserResolver } from "../user/index.browser"

export class CampaignManager implements SessionListener {
  constructor(
    private readonly storage: CampaignStorage,
    private readonly parser: CampaignParser,
    private readonly core: HackleCore,
    private readonly userManager: UserManager,
    private readonly userResolver: HackleUserResolver
  ) {}

  startNewCampaign(user: User, campaign: Campaign, timestamp: number): void {
    const campaignProperties: Campaign = {
      ...EMPTY_CAMPAIGN,
      ...campaign
    }

    const builder = new PropertyOperationsBuilder()
    for (let [key, value] of Object.entries(campaignProperties)) {
      if (value) {
        builder.set(key, value)
      } else {
        builder.unset(key)
      }
    }
    const operations = builder.build()

    const event = operations.toEvent()
    const hackleUser = this.userResolver.resolve(user)
    this.core.track(event, hackleUser, timestamp)
    this.userManager.updateUserProperties(operations)
  }

  startNewCampaignIfNeeded(user: User, timestamp: number): void {
    const previousCampaign = this.storage.get()
    const newCampaign = this.parser.parse()
    const domain = this.parser.parseDomain()
    if (isNewCampaign(previousCampaign, newCampaign, domain)) {
      this.storage.set(newCampaign)
      this.startNewCampaign(user, newCampaign, timestamp)
    }
  }

  onSessionEnded(sessionId: string, user: User, timestamp: number): void {}

  onSessionStarted(sessionId: string, user: User, timestamp: number): void {
    this.startNewCampaignIfNeeded(user, timestamp)
  }
}
