import { TargetCondition, TargetKeyType } from "../../model/model"
import ValueOperatorMatcher from "./ValueOperatorMatcher"
import { OperatorMatcherFactory } from "./OperatorMatcher"
import { ValueMatcherFactory } from "./ValueMatcher"
import Evaluator, { EvaluatorContext, EvaluatorRequest } from "../evalautor/Evaluator"
import UserConditionMatcher, { UserValueResolver } from "./UserConditionMatcher"
import SegmentConditionMatcher, { SegmentMatcher } from "./SegmentConditionMatcher"
import ExperimentConditionMatcher, {
  AbTestConditionMatcher,
  FeatureFlagConditionMatcher
} from "./ExperimentConditionMatcher"
import EventConditionMatcher, { EventValueResolver } from "./EventConditionMatcher"

export default interface ConditionMatcher {
  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean
}

export class ConditionMatcherFactory {
  private readonly userConditionMatcher: UserConditionMatcher
  private readonly eventConditionMatcher: EventConditionMatcher
  private readonly segmentConditionMatcher: SegmentConditionMatcher
  private readonly experimentConditionMatcher: ExperimentConditionMatcher

  constructor(evaluator: Evaluator) {
    const valueOperatorMatcher = new ValueOperatorMatcher(new ValueMatcherFactory(), new OperatorMatcherFactory())
    this.userConditionMatcher = new UserConditionMatcher(new UserValueResolver(), valueOperatorMatcher)
    this.eventConditionMatcher = new EventConditionMatcher(new EventValueResolver(), valueOperatorMatcher)
    this.segmentConditionMatcher = new SegmentConditionMatcher(new SegmentMatcher(this.userConditionMatcher))
    this.experimentConditionMatcher = new ExperimentConditionMatcher(
      new AbTestConditionMatcher(evaluator, valueOperatorMatcher),
      new FeatureFlagConditionMatcher(evaluator, valueOperatorMatcher)
    )
  }

  getMatcher(type: TargetKeyType): ConditionMatcher {
    switch (type) {
      case "USER_ID":
      case "USER_PROPERTY":
      case "HACKLE_PROPERTY":
        return this.userConditionMatcher
      case "EVENT_PROPERTY":
        return this.eventConditionMatcher
      case "SEGMENT":
        return this.segmentConditionMatcher
      case "AB_TEST":
      case "FEATURE_FLAG":
        return this.experimentConditionMatcher
    }
  }
}
