import { InAppMessageRequest } from "../evalautor/iam/InAppMessageRequest"
import { EvaluatorContext } from "../evalautor/Evaluator"
import { InAppMessageContextMessage, InAppMessageUserOverride } from "../../model/model"
import ObjectUtil from "../../util/ObjectUtil"
import TargetMatcher from "../match/TargetMatcher"
import InAppMessageHiddenStorage from "./InAppMessageHiddenStorage"

export class InAppMessageResolver {
  resolve(request: InAppMessageRequest, context: EvaluatorContext): InAppMessageContextMessage {
    const inAppMessage = request.inAppMessage
    const lang = inAppMessage.messageContext.defaultLang
    const message = inAppMessage.messageContext.messages.find((it) => it.lang === lang)
    if (ObjectUtil.isNullOrUndefined(message)) {
      throw new Error(`InAppMessage must be decided [${inAppMessage.id}]`)
    }
    return message
  }
}

export interface InAppMessageMatcher {
  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean
}

export class UserOverrideInAppMessageMatcher implements InAppMessageMatcher {
  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean {
    return request.inAppMessage.targetContext.overrides.some((it) => this.isUserOverridden(request, it))
  }

  private isUserOverridden(request: InAppMessageRequest, userOverride: InAppMessageUserOverride): boolean {
    const identifier = request.user.identifiers[userOverride.identifierType]
    if (ObjectUtil.isNullOrUndefined(identifier)) {
      return false
    }
    return userOverride.identifiers.includes(identifier)
  }
}

export class TargetInAppMessageMatcher implements InAppMessageMatcher {
  constructor(private readonly targetMatcher: TargetMatcher) {}

  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean {
    const targets = request.inAppMessage.targetContext.targets
    if (targets.length === 0) {
      return true
    }
    return targets.some((it) => this.targetMatcher.matches(request, context, it))
  }
}

export class HiddenInAppMessageMatcher implements InAppMessageMatcher {
  constructor(private readonly storage: InAppMessageHiddenStorage) {}

  matches(request: InAppMessageRequest, context: EvaluatorContext): boolean {
    return this.storage.exist(request.inAppMessage, request.timestamp)
  }
}
