import Event from "../event/Event"
import { IdentifierType, User } from "../model/model"
import { SessionListener } from "./SessionListener"

export interface SessionManager {
  readonly sessionId: string
  readonly currentSessionId: string | null
  readonly lastEventTime: number | null

  addListener(listener: SessionListener): void

  startNewSession(previousUser: User | null, user: User, timestamp: number): string

  startNewSessionIfNeeded(user: User, timestamp: number): string

  updateLastEventTime(timestamp: number): void
}

export const decorateEventWithSession = <T extends Event>(event: T, sessionManager: SessionManager): T => {
  if (sessionManager.currentSessionId === null) {
    return event
  }

  if (!event.user.identifiers[IdentifierType.SESSION]) {
    const newEvent = event.copyWithUser({
      ...event.user,
      identifiers: {
        ...event.user.identifiers,
        [IdentifierType.SESSION]: sessionManager.sessionId
      }
    })

    return newEvent as T
  }

  return event
}
