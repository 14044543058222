import { EventDto } from "../../../core/internal/event/dto"
import { IEventRepository } from "../../../core/internal/event/EventRepository"
import { IStorage } from "../../../core/internal/storage/Storage"

export class EventRepositoryImpl implements IEventRepository<EventDto> {
  constructor(private storage: IStorage, private storageKey: string, private maxSize: number) {}

  public read() {
    return new Promise<EventDto[]>((resolve) => {
      const stringified = this.storage.getItem(this.storageKey)

      if (stringified) {
        const parsed = JSON.parse(stringified)
        resolve(parsed)
      }

      resolve([])
    })
  }

  public save(items: EventDto[]) {
    if (items.length === 0) {
      return this.clear()
    }

    return new Promise<void>((resolve) => {
      const stringified = JSON.stringify(items.slice(-this.maxSize))
      this.storage.setItem(this.storageKey, stringified)
      resolve()
    })
  }

  public add(addees: EventDto[]) {
    return this.read().then((data) => {
      return this.save(data.concat(addees).slice(-this.maxSize))
    })
  }

  public delete(deletees: EventDto[]) {
    return this.read().then((data) => {
      const newData = data.filter((item) => deletees.findIndex((deletee) => deletee.insertId === item.insertId) < 0)
      return this.save(newData)
    })
  }

  public clear() {
    return new Promise<void>((resolve) => {
      this.storage.removeItem(this.storageKey)
      resolve()
    })
  }
}
