import { EvaluatorEvaluation, EvaluatorRequest } from "../evaluation/evalautor/Evaluator"
import Event from "./Event"
import { Properties } from "../model/model"
import ExperimentEvaluation from "../evaluation/evalautor/experiment/ExperimentEvaluation"
import RemoteConfigEvaluation from "../evaluation/evalautor/remoteconfig/RemoteConfigEvaluation"
import { Clock } from "../util/TimeUtil"

export default class EventFactory {
  private static ROOT_TYPE = "$targetingRootType"
  private static ROOT_ID = "$targetingRootId"
  private static CONFIG_ID_PROPERTY_KEY = "$parameterConfigurationId"
  private static EXPERIMENT_VERSION_KEY = "$experiment_version"
  private static EXECUTION_VERSION_KEY = "$execution_version"

  private readonly clock: Clock

  constructor(clock: Clock) {
    this.clock = clock
  }

  create(request: EvaluatorRequest, evaluation: EvaluatorEvaluation): Event[] {
    const timestamp = this.clock.currentMillis()
    const events = new Array<Event>()

    const rootEvent = this.createEvent(request, evaluation, timestamp, {})
    events.push(rootEvent)

    evaluation.targetEvaluations.forEach((targetEvaluation) => {
      const properties: Properties = {
        [EventFactory.ROOT_TYPE]: request.key.type,
        [EventFactory.ROOT_ID]: request.key.id
      }
      const targetEvent = this.createEvent(request, targetEvaluation, timestamp, properties)
      events.push(targetEvent)
    })

    return events
  }

  private createEvent(
    request: EvaluatorRequest,
    evaluation: EvaluatorEvaluation,
    timestamp: number,
    properties: Properties
  ): Event {
    if (evaluation instanceof ExperimentEvaluation) {
      properties[EventFactory.CONFIG_ID_PROPERTY_KEY] = evaluation.config?.id
      properties[EventFactory.EXPERIMENT_VERSION_KEY] = evaluation.experiment.version
      properties[EventFactory.EXECUTION_VERSION_KEY] = evaluation.experiment.executionVersion
      return Event.exposure(request.user, evaluation, properties, timestamp)
    }

    if (evaluation instanceof RemoteConfigEvaluation) {
      return Event.remoteConfig(
        request.user,
        evaluation,
        {
          ...properties,
          ...evaluation.properties
        },
        timestamp
      )
    }

    throw new Error(`Unsupported Evaluation [${typeof evaluation}]`)
  }
}
