import Logger, { logLevels } from "./core/internal/logger"
import HttpRequest from "./hackle/http/index.browser"
import HackleClientImpl, { BrowserHackleClient as HackleClient, DevTools, PageView } from "./hackle/index.browser"
import {
  BROWSER_BATCH_SIZE,
  BROWSER_FLUSH_INTERVAL,
  BROWSER_MIN_POOL_INTERVAL,
  COOKIE_EXPIRE_DAYS,
  DEFAULT_CDN_URL,
  DEFAULT_CLIENT_SDK_URL,
  DEFAULT_EVENT_URL,
  DEFAULT_MONITORING_URL,
  DEFAULT_SESSION_TIMEOUT_MILLIS,
  IAM_HIDE_STORAGE_PREFIX,
  IAM_IMPRESSION_STORAGE_PREFIX,
  IAM_RENDERER_VERSION,
  LOCAL_STORAGE_KEY_PREFIX,
  METRIC_FLUSH_INTERVAL,
  OVERRIDE_AB_STORAGE_PREFIX,
  OVERRIDE_FF_STORAGE_PREFIX,
  SDK_KEY_HEADER,
  SDK_NAME_HEADER,
  SDK_VERSION,
  SDK_VERSION_HEADER
} from "./config"
import EventDispatcher from "./hackle/event/dispatcher/index.browser"
import PollingWorkspaceFetcher from "./core/internal/workspace/PollingWorkspaceFetcher"
import { getUserId, HackleUserResolver, removeUserId, setUserId } from "./hackle/user/index.browser"
import "core-js/features/promise"
import "core-js/features/array"
import EventProcessorImpl, { ExposureEventDedupDeterminer } from "./hackle/event/processor/index.browser"
import HackleCore from "./core/HackleCore"
import { GlobalErrorHandler } from "./hackle/trace/GlobalErrorHandler"
import { EventRepositoryImpl } from "./hackle/event/repository/index.browser"
import { UserManagerImpl, UserStorage } from "./hackle/user/manager/index.browser"
import { SessionManagerImpl } from "./hackle/session/manager/index.browser"
import { CookieStorage } from "./hackle/storage/CookieStorage.browser"
import { SessionEventTracker } from "./hackle/session/track/index.browser"
import { KeyTransformStorage, ListStorage, WindowLocalStorage } from "./core/internal/storage/Storage"
import { MonitoringMetricRegistry } from "./core/internal/metrics/monitoring/MonitoringMetricRegistry"
import { Metrics } from "./core/internal/metrics/Metrics"
import { HackleUserManualOverrideStorage } from "./core/internal/evaluation/target/ManualOverrideStorage"
import { HackleUserExplorerBase, HackleUserExplorerImpl } from "./core/internal/user/UserExplorer"
import HackleUrlResolver from "./hackle/http/HackleUrlResolver"
import { InAppMessageHiddenLocalStorage } from "./hackle/iam/storage/InAppMessageHiddenLocalStorage"
import { HackleCoreContext } from "./core/internal/HackleCoreContext"
import TargetMatcher from "./core/internal/evaluation/match/TargetMatcher"
import {
  InAppMessageActionHandlerFactory,
  InAppMessageCloseActionHandler,
  InAppMessageHiddenActionHandler,
  InAppMessageLinkActionHandler,
  InAppMessageLinkAndCloseActionHandler,
  InAppMessageLinkNewTabActionHandler,
  InAppMessageLinkNewTabAndCloseActionHandler,
  InAppMessageLinkNewWindowActionHandler,
  InAppMessageLinkNewWindowAndCloseActionHandler
} from "./hackle/iam/ui/event/InAppMessageActionHandler"
import { InAppMessageEventTracker } from "./hackle/iam/ui/event/InAppMessageEventTracker"
import { InAppMessageUi } from "./hackle/iam/ui/InAppMessageUi"
import InAppMessageManager from "./hackle/iam/trigger/InAppMessageManager"
import { InAppMessageDeterminer } from "./hackle/iam/trigger/InAppMessageDeterminer"
import { SystemClock } from "./core/internal/util/TimeUtil"
import { InAppMessageViewFactory } from "./hackle/iam/ui/view/InAppMessageViewFactory"
import { InAppMessageRenderScriptLoader } from "./hackle/iam/ui/script/InAppMessageRenderScriptLoader"
import { User } from "./core/internal/model/model"
import { isSameUser } from "./core/internal/user/UserManager"
import {
  InAppMessageEventTriggerFrequencyCapDeterminer,
  InAppMessageEventTriggerRuleDeterminer
} from "./hackle/iam/trigger/InAppMessageEventTriggerDeterminer"
import { InAppMessageImpressionStorage } from "./hackle/iam/storage/InAppMessageImpressionStorage"
import {
  InAppMessageActionEventProcessor,
  InAppMessageCloseEventProcessor,
  InAppMessageEventProcessorFactory,
  InAppMessageImpressionEventProcessor
} from "./hackle/iam/ui/event/InAppMessageEventProcessor"
import { InAppMessageEventHandler } from "./hackle/iam/ui/event/InAppMessageEventHandler"
import { InAppMessageEventMatcher } from "./hackle/iam/trigger/InAppMessageEventMatcher"
import {
  InAppMessageActionInteractionHandler,
  InAppMessageCloseInteractionHandler,
  InAppMessageInteractionHandlerFactory
} from "./hackle/iam/ui/event/InAppMessageInteractionHandler"
import { UrlLinkHandler, UrlLinkNewTabHandler, UrlLinkNewWindowHandler } from "./hackle/iam/ui/internal/UrlHandler"
import { CampaignManager } from "./hackle/attribution/CampaignManager"
import { CampaignStorage } from "./hackle/attribution/CampaignStorage"
import { CampaignParser } from "./hackle/attribution/CampaignParser"

const log = Logger.log

let hackleClientCache: HackleClient | null = null

interface Config {
  user?: User

  debug?: boolean
  auto_track_page_view?: boolean
  pollingIntervalMillis?: number
  exposureEventDedupIntervalMillis?: number
  sessionTimeoutMillis?: number
  devTool?: DevTools["manager"]
  autoOpenDevTool?: boolean
  sameSiteCookie?: string
  secureCookie?: boolean

  [key: string]: any
}

interface InternalConfig {
  user?: User

  debug: boolean
  log_disabled: boolean
  auto_track_page_view: boolean
  pollingIntervalMillis: number
  exposureEventDedupIntervalMillis: number
  sessionTimeoutMillis: number
  devTool?: DevTools["manager"]
  autoOpenDevTool?: boolean

  sdkUrl: string
  eventUrl: string
  monitoringUrl: string
  cdnUrl: string
  sameSiteCookie: string
  secureCookie: boolean

  SDK_NAME_HEADER: string
  SDK_VERSION_HEADER: string

  IAM_RENDERER_VERSION_HEADER: string
}

const defaultConfig: InternalConfig = {
  user: undefined,

  debug: false,
  log_disabled: false,
  auto_track_page_view: true,
  pollingIntervalMillis: -1,
  exposureEventDedupIntervalMillis: -1,
  sessionTimeoutMillis: DEFAULT_SESSION_TIMEOUT_MILLIS,
  autoOpenDevTool: false,

  sdkUrl: DEFAULT_CLIENT_SDK_URL,
  eventUrl: DEFAULT_EVENT_URL,
  monitoringUrl: DEFAULT_MONITORING_URL,
  cdnUrl: DEFAULT_CDN_URL,
  sameSiteCookie: "LAX",
  secureCookie: false,

  SDK_NAME_HEADER: "javascript-sdk_browser",
  SDK_VERSION_HEADER: SDK_VERSION,
  IAM_RENDERER_VERSION_HEADER: IAM_RENDERER_VERSION
}

function createInstance(sdkKey: string, _config?: Config): HackleClient {
  // Config & Validate
  const config: InternalConfig = {
    ...defaultConfig,
    ..._config
  }
  const localStorage = new WindowLocalStorage()

  // Logging
  Logger.initCounter((logLevel) => Metrics.counter("log", { level: logLevel }))

  if (config.log_disabled) {
    Logger.setLogLevel(logLevels.DISABLE)
  } else {
    if (config.debug) {
      Logger.setLogLevel(logLevels.DEBUG)
    }
  }

  log.debug("sdkKey : " + sdkKey)
  if (!sdkKey) {
    log.error("SDK Key must not be null")
  }

  if (hackleClientCache) {
    log.debug("use already exists hackleClient")
    return hackleClientCache
  }

  let useBeacon = false

  if (typeof window !== "undefined") {
    // @ts-ignore
    useBeacon = window && window.navigator && window.navigator.sendBeacon && true
    if (useBeacon) {
      log.debug("support sendBeacon API")
    }
  }

  // - WorkspaceFetcher
  let pollingIntervalMillis = -1
  if (
    config.pollingIntervalMillis !== undefined &&
    typeof config.pollingIntervalMillis === "number" &&
    config.pollingIntervalMillis > 0
  ) {
    pollingIntervalMillis = Math.max(config.pollingIntervalMillis, BROWSER_MIN_POOL_INTERVAL)
  }

  const workspaceFetcher = new PollingWorkspaceFetcher(sdkKey, HttpRequest, {
    fetchUrl: HackleUrlResolver.clientFetch(config.sdkUrl, sdkKey),
    updateInterval: pollingIntervalMillis,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  // - UserManager & SessionManager

  const cookieStorage = CookieStorage.getInstance({
    days: COOKIE_EXPIRE_DAYS,
    isCrossSubdomain: true,
    sameSite: config.sameSiteCookie,
    isSecure: config.secureCookie
  })
  const userStorage = new UserStorage(KeyTransformStorage.postfix(cookieStorage, `_${sdkKey}`))
  const previousUser = userStorage.getUser()
  const initUser = config.user || null
  const userManager = new UserManagerImpl(userStorage, getUserId(), previousUser, initUser)

  const sessionManager = new SessionManagerImpl(
    config.sessionTimeoutMillis,
    KeyTransformStorage.postfix(cookieStorage, `_${sdkKey.slice(8)}`)
  )
  userManager.addListener(sessionManager)

  // - EventProcessor

  const repository = new EventRepositoryImpl(localStorage, `${LOCAL_STORAGE_KEY_PREFIX}_${sdkKey}`, BROWSER_BATCH_SIZE)

  const eventDispatcher = new EventDispatcher(sdkKey, HttpRequest, {
    dispatchUrl: HackleUrlResolver.clientDispatch(config.eventUrl),
    beaconDispatchUrl: HackleUrlResolver.beaconDispatch(config.eventUrl, sdkKey),
    useBeacon: useBeacon,
    headers: {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER as string,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER as string
    }
  })

  let dedupIntervalMillis: number = -1
  if (
    config.exposureEventDedupIntervalMillis !== undefined &&
    typeof config.exposureEventDedupIntervalMillis === "number" &&
    config.exposureEventDedupIntervalMillis !== -1
  ) {
    if (config.exposureEventDedupIntervalMillis < 1000 || config.exposureEventDedupIntervalMillis > 1000 * 60 * 60) {
      log.warn(
        "Exposure event dedup interval is outside allowed range[1_000ms..3_600_000ms]. Setting to default value[no dedup]."
      )
      dedupIntervalMillis = -1
    } else {
      dedupIntervalMillis = config.exposureEventDedupIntervalMillis
    }
  }

  const dedupDeterminer = new ExposureEventDedupDeterminer(dedupIntervalMillis)
  const eventProcessor = new EventProcessorImpl(
    eventDispatcher,
    BROWSER_BATCH_SIZE,
    BROWSER_FLUSH_INTERVAL,
    repository,
    dedupDeterminer,
    sessionManager,
    userManager
  )

  // - Core

  const abOverrideStorage = new HackleUserManualOverrideStorage(
    new ListStorage(cookieStorage, `${OVERRIDE_AB_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}`)
  )
  const ffOverrideStorage = new HackleUserManualOverrideStorage(
    new ListStorage(cookieStorage, `${OVERRIDE_FF_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}`)
  )

  const inAppMessageHiddenStorage = new InAppMessageHiddenLocalStorage(
    KeyTransformStorage.prefix(localStorage, `${IAM_HIDE_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}_`)
  )

  const inAppMessageImpressionStorage = new InAppMessageImpressionStorage(
    KeyTransformStorage.prefix(localStorage, `${IAM_IMPRESSION_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}_`)
  )

  const core = HackleCore.create(
    workspaceFetcher,
    eventProcessor,
    [abOverrideStorage, ffOverrideStorage],
    inAppMessageHiddenStorage
  )

  // - UserResolver

  const hackleUserResolver = new HackleUserResolver(userManager)

  // CampaignManager, SessionTracker

  const campaignManager = new CampaignManager(
    new CampaignStorage(KeyTransformStorage.postfix(cookieStorage, `_${sdkKey.slice(0, 8)}`)),
    new CampaignParser(),
    core,
    userManager,
    hackleUserResolver
  )
  const sessionEventTracker = new SessionEventTracker(hackleUserResolver, core)

  // Add SessionListener
  // CampaignManager MUST be added before SessionTracker to include campaign properties in session events.
  sessionManager.addListener(campaignManager)
  sessionManager.addListener(sessionEventTracker)

  // - InAppMessage
  const inAppMessageEventTracker = new InAppMessageEventTracker(core)

  const inAppMessageActionHandlerFactory = new InAppMessageActionHandlerFactory([
    new InAppMessageCloseActionHandler(),
    new InAppMessageLinkActionHandler(new UrlLinkHandler()),
    new InAppMessageLinkAndCloseActionHandler(new UrlLinkHandler()),
    new InAppMessageHiddenActionHandler(inAppMessageHiddenStorage, SystemClock.instance),
    new InAppMessageLinkNewWindowActionHandler(new UrlLinkNewWindowHandler()),
    new InAppMessageLinkNewWindowAndCloseActionHandler(new UrlLinkNewWindowHandler()),
    new InAppMessageLinkNewTabActionHandler(new UrlLinkNewTabHandler()),
    new InAppMessageLinkNewTabAndCloseActionHandler(new UrlLinkNewTabHandler())
  ])
  const inAppMessageEventProcessorFactory = new InAppMessageEventProcessorFactory([
    new InAppMessageImpressionEventProcessor(inAppMessageImpressionStorage),
    new InAppMessageActionEventProcessor(inAppMessageActionHandlerFactory),
    new InAppMessageCloseEventProcessor()
  ])
  const inAppMessageEventHandler = new InAppMessageEventHandler(
    SystemClock.instance,
    inAppMessageEventTracker,
    inAppMessageEventProcessorFactory
  )
  const inAppMessageInteractionHandlerFactory = new InAppMessageInteractionHandlerFactory([
    new InAppMessageCloseInteractionHandler(),
    new InAppMessageActionInteractionHandler(inAppMessageEventHandler)
  ])
  const inAppMessageRenderScriptLoader = new InAppMessageRenderScriptLoader(
    HackleUrlResolver.inAppMessageRenderer(config.cdnUrl, config.IAM_RENDERER_VERSION_HEADER)
  )

  const inAppMessageUi = new InAppMessageUi(
    new InAppMessageViewFactory(inAppMessageRenderScriptLoader),
    inAppMessageEventHandler,
    inAppMessageInteractionHandlerFactory
  )
  const inAppMessageEventMatcher = new InAppMessageEventMatcher(
    new InAppMessageEventTriggerRuleDeterminer(HackleCoreContext.get<TargetMatcher>("targetMatcher")),
    new InAppMessageEventTriggerFrequencyCapDeterminer(inAppMessageImpressionStorage)
  )
  const inAppMessageDeterminer = new InAppMessageDeterminer(workspaceFetcher, inAppMessageEventMatcher, core)
  const inAppMessageManager = new InAppMessageManager(eventProcessor, inAppMessageDeterminer, inAppMessageUi)

  // - UserExplorer

  const userExplorer = new HackleUserExplorerImpl(
    core,
    userManager,
    hackleUserResolver,
    abOverrideStorage,
    ffOverrideStorage
  )

  const isValidDevToolConfig = config.devTool?.userExplorer && typeof config.devTool?.userExplorer === "function"
  const devTools = config.devTool && isValidDevToolConfig ? { manager: config.devTool, userExplorer } : undefined

  const hackleClient = new HackleClientImpl(core, hackleUserResolver, sessionManager, userManager, devTools)

  hackleClientCache = hackleClient

  if (config.autoOpenDevTool) {
    if (!config.devTool) {
      log.error("DevTool is not provided")
    } else {
      hackleClient.showUserExplorer()
    }
  }

  const drainRepository = () => {
    eventProcessor.drainRepository()
  }

  const monitoringMetricRegistry = new MonitoringMetricRegistry(
    config.monitoringUrl,
    METRIC_FLUSH_INTERVAL,
    HttpRequest,
    {
      [SDK_NAME_HEADER]: config.SDK_NAME_HEADER,
      [SDK_VERSION_HEADER]: config.SDK_VERSION_HEADER,
      [SDK_KEY_HEADER]: sdkKey
    },
    useBeacon
  )

  const flush = () => {
    removeUserId()
    sessionManager.updateLastEventTime(new Date().getTime())
    hackleClient.close()
    Metrics.globalRegistry.close()
  }

  document.addEventListener("visibilitychange", () => {
    if (!document.hidden) {
      drainRepository()
    }
  })

  window.addEventListener("pageshow", drainRepository)

  window.addEventListener("onpagehide" in window ? "pagehide" : "unload", flush)

  Metrics.addRegistry(monitoringMetricRegistry)

  const refresh = () => {
    const timestamp = Date.now()
    const currentUser = userManager.currentUser

    // Session Refresh
    const currentSessionId = sessionManager.currentSessionId
    let newSessionId: string
    if (isSameUser(previousUser, currentUser)) {
      newSessionId = sessionManager.startNewSessionIfNeeded(currentUser, timestamp)
    } else {
      newSessionId = sessionManager.startNewSession(previousUser, currentUser, timestamp)
    }

    // Campaign Refresh
    if (newSessionId === currentSessionId) {
      // If new session is started, CampaignManager automatically restarts the campaign.
      // Attempts to cover when session is not newly started, but the campaign changes.
      campaignManager.startNewCampaignIfNeeded(currentUser, timestamp)
    }
  }

  refresh()

  hackleClient.onReady(() => {
    new GlobalErrorHandler().install(core, hackleUserResolver)
    _installPageViewAutoTrack(hackleClient, config)
  })

  return hackleClient
}

function _installPageViewAutoTrack(client: HackleClient, config: InternalConfig) {
  if (!config.auto_track_page_view) {
    return
  }
  client.trackPageView()

  function customEvent(type: string): Event {
    if (typeof window.Event === "function") return new Event(type)

    const params = { bubbles: false, cancelable: false, detail: undefined }
    const evt = document.createEvent("CustomEvent")
    evt.initCustomEvent(type, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  try {
    history.pushState = ((f) =>
      function pushState() {
        try {
          // @ts-ignore
          var ret = f.apply(this, arguments)
          window.dispatchEvent(customEvent("locationchange"))
          return ret
        } catch (e) {
          if (e instanceof Error) {
            log.error(e)
          } else {
            try {
              log.error(e as string)
            } catch (ex) {}
          }
        }
      })(history.pushState)

    history.replaceState = ((f) =>
      function replaceState() {
        try {
          // @ts-ignore
          var ret = f.apply(this, arguments)
          window.dispatchEvent(customEvent("locationchange"))
          return ret
        } catch (e) {
          if (e instanceof Error) {
            log.error(e)
          } else {
            try {
              log.error(e as string)
            } catch (ex) {}
          }
        }
      })(history.replaceState)

    window.addEventListener("popstate", () => {
      try {
        window.dispatchEvent(customEvent("locationchange"))
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
    window.addEventListener("locationchange", () => {
      try {
        client.trackPageView()
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
  } catch (e) {
    if (e instanceof Error) {
      log.error(e)
    } else {
      try {
        log.error(e as string)
      } catch (ex) {}
    }
  }
}

export { PropertyOperationsBuilder, PropertyOperations } from "./hackle/property/PropertyOperations"
export { HackleUserExplorerBase }
export { createInstance }
export { getUserId }
export { setUserId }
export { removeUserId }
export { HackleClient }
export { PageView }
export { Logger }
export { Config }

export * from "./core/internal/model/model"

export default {
  createInstance,
  getUserId,
  setUserId,
  removeUserId,
  Logger
}
