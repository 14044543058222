import { BROWSER_BATCH_SIZE } from "../../../../config"
import { Headers, HttpRequest } from "../../http"
import Logger from "../../logger"
import { Decision, FeatureFlagDecision, InAppMessageDecision, RemoteConfigDecision } from "../../model/model"
import CollectionUtil from "../../util/CollectionUtil"
import ObjectUtil from "../../util/ObjectUtil"
import { Counter, isCounterMetric } from "../Counter"
import { FlushCounter } from "../flush/FlushCounter"
import { FlushMetricRegistry } from "../flush/FlushMetricRegistry"
import { FlushTimer } from "../flush/FlushTimer"
import { Metric, MetricId } from "../Metric"
import { Metrics } from "../Metrics"
import { isTimerMetric, Timer, TimerSample } from "../Timer"

export class MonitoringMetricRegistry extends FlushMetricRegistry {
  constructor(
    private monitoringBaseUrl: string,
    flushIntervalMillis: number,
    private httpClient: HttpRequest,
    private httpHeader: Headers,
    private useBeacon?: boolean
  ) {
    super(flushIntervalMillis)
    this.start()
    this.monitoringBaseUrl = monitoringBaseUrl
  }

  private monitoringEndPoint = `${this.monitoringBaseUrl}/metrics`
  private shouldUseBeacon = false

  protected flushMetrics(metrics: Metric[]): void {
    CollectionUtil.chunked(metrics.filter(this.isDispatchTarget.bind(this)), BROWSER_BATCH_SIZE).forEach(
      (targetMetrics) => {
        this.dispatch(targetMetrics)
      }
    )
  }

  // Dispatch only measured metrics
  private isDispatchTarget(metric: Metric): boolean {
    if (isCounterMetric(metric) || isTimerMetric(metric)) return metric.count() > 0

    return false
  }

  private dispatch(metrics: Metric[]) {
    if (this.useBeacon && this.shouldUseBeacon) {
      this.httpClient.sendBeacon(this.monitoringEndPoint, this.batch(metrics), this.httpHeader, () => {
        Logger.log.debug("Beacon called.")
      })
      return
    }

    this.httpClient.postRequest(
      this.monitoringEndPoint,
      this.batch(metrics),
      this.httpHeader,
      () => undefined,
      () => {
        Logger.log.debug("Failed to flushing metrics.")
      }
    )
  }

  private batch(metrics: Metric[]) {
    return {
      metrics: metrics.map((metric) => ({
        name: metric.id.name,
        type: metric.id.type,
        tags: metric.id.tags,
        measurements: ObjectUtil.fromMap(
          CollectionUtil.associate(metric.measure(), (measurement) => [measurement.field, measurement.value])
        )
      }))
    }
  }

  createCounter(id: MetricId): Counter {
    return new FlushCounter(id)
  }

  createTimer(id: MetricId): Timer {
    return new FlushTimer(id)
  }

  close() {
    this.shouldUseBeacon = true
    super.close()
  }
}

export class DecisionMetrics {
  static experiment(sample: TimerSample, key: number, decision: Decision) {
    const timer = Metrics.timer("experiment.decision", {
      key: String(key),
      variation: decision.variation,
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static featureFlag(sample: TimerSample, key: number, decision: FeatureFlagDecision) {
    const timer = Metrics.timer("feature.flag.decision", {
      key: String(key),
      on: String(decision.isOn),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static remoteConfig(sample: TimerSample, key: string, decision: RemoteConfigDecision) {
    const timer = Metrics.timer("remote.config.decision", {
      key: String(key),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static inAppMessage(sample: TimerSample, key: number, decision: InAppMessageDecision) {
    const timer = Metrics.timer("iam.decision", {
      key: String(key),
      show: String(decision.isShow()),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }
}

export class ApiCallMetrics {
  static record(operation: string, sample: TimerSample, isSuccess: boolean) {
    const timer = Metrics.timer("api.call", {
      operation,
      success: String(isSuccess)
    })
    sample.stop(timer)
  }
}
