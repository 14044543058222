export default class LocationSearchParser {
  search: string

  constructor(search: string) {
    this.search = search
  }

  getQueryParam(name: string): string {
    const params = this.getQueryParams(() => true)
    return params[name] || ""
  }

  getQueryParams(condition?: (key: string, value: string) => boolean): Record<string, string | undefined> {
    const params: { [key: string]: string } = {}
    const queries = this.search.substring(1).split("&")

    for (const query of queries) {
      const [key, value] = query.split("=")
      if (!condition || condition(key, value)) {
        params[key] = decodeURIComponent(value)
      }
    }

    return params
  }

  static getQueryParams(): Record<string, string | undefined> {
    if (typeof window === "undefined") {
      return {}
    }
    return new LocationSearchParser(window.location.search).getQueryParams()
  }
}
